input[type=checkbox] {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0
}
input[type=checkbox]:focus+label{

}
input[type=checkbox]:checked+label:before {
    background-color: #8fce6f;
    border-color: #8fce6f;
}
input[type=checkbox]:checked+label:after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23ffffff'%3E%3Cpath d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'/%3E%3C/svg%3E");
	-webkit-background-size: contain;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

input[type=checkbox]:checked+label:after,
input[type=checkbox]:checked+label:hover:after {
	opacity: 1;
}

input[type=checkbox] + label {
	display: block;
	float: left;
	background-repeat: no-repeat;
	background-position: left center;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	-webkit-touch-callout: none;
	padding-left: 36px;
	margin-right: 30px;
	position: relative;
	font-size: inherit;
	min-height: 24px;
	padding-top: 2px;

	.form__component__column & {
		margin-right: 0;
	}

}

input[type=checkbox]+label:before {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: #fff;
    content: '';
    top: 0;
    border:1px solid rgba(#000,0.1);
}

input[type=checkbox]+label:after {
    position: absolute;
    left: 6px;
    top: 6px;
    bottom: 6px;
    content: '';
    width: 12px;
    opacity: 0;
    transition:.2s;
}
input[type=checkbox]:checked+label:before {

}
input[type=checkbox]+label a {
    line-height: 24px;
}
input[type=radio] {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0
}
input[type=radio]:focus+label{

}
input[type=radio]:checked+label:before {
    background-color: #8fce6f;
    border-color: #8fce6f;
}
input[type=radio]:checked+label:after {
	background-color: #ffffff;
}

input[type=radio]:checked+label:after,
input[type=radio]:checked+label:hover:after {
	opacity: 1;
}
input[type=radio]+label {
    display: block;
    float: left;
    background-repeat: no-repeat;
    background-position: left center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-touch-callout: none;
    user-select: none;
    -webkit-touch-callout: none;
    padding-left: 36px;
    margin-right: 30px;
    position: relative;
    font-size: inherit;
    min-height: 24px;
    line-height: 24px;
}

input[type=radio]+label:before {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: #fff;
    content: '';
    top: 0;
    border-radius: 50%;
    border:1px solid rgba(#000,0.1);
}

input[type=radio]+label:after {
    position: absolute;
    left: 9px;
    top: 9px;
    content: '';
    width: 5px;
    height: 5px;
    opacity: 0;
    transition:.2s;
    border-radius: 50%;
}
input[type=radio]:checked+label:before {

}
input[type=radio]+label a {
    line-height: 24px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="file"],
input[type="search"],
textarea,
select {
	width: 100%;
	display: block;
	padding: 5px 12px;
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: none;
	border: 1px solid $border-color;
	background-color: #ffffff;
	height: 36px;
	outline: 0;
	border-radius: 0;
	font-size: 14px;
	@include placeholder {
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-box-shadow: 0 0 0px 1000px #fff inset;
	}

	&:focus {
		background-color: #fff;
		border: 1px solid #bcbcbc !important;
	}
}

textarea{
	height: auto;
	 @include placeholder{
    	font-style: italic;
    }
}
input[disabled="disabled"],
input[disabled]{
	cursor:not-allowed;
	+ label{
		cursor:not-allowed;
	}
}

.form__element--select {
	background: white;
	select {
		background: none;
	}
	.dropdown-handle {
		height: 36px;
		width: 36px;
	}

}

select,
datalist {
	-webkit-appearance: none;
	appearance: none;
	background-repeat: no-repeat;
	line-height: normal;
	outline: 0;
}

select::-ms-expand {
    display: none;

}
textarea{
	height: 180px !important;
	resize: none;
	padding-top: 12px;
}
.form{
	//padding-bottom: 36px;
}
.form__component{
	display: flex;
	flex-wrap:wrap;
	//width: 100%;

	&.form__component--hidden{
		display: none;
	}
	&.form__component--error,
	.form__component__column.form__component--error{
		label,
		input,
		textarea,
		select{
			color: $form-error-color;
			border-color:$form-error-color;

			@include placeholder{
		    	color: $form-error-color;
		    }

			&:before,
			&:after{
				color: $form-error-color;
				border-color:$form-error-color;

			}

		}
		input,
		textarea{
			background-color: rgba($form-error-color, 0.1);
		}

		.form__element{
			+ span{
				color: $form-error-color;
				margin-top: -6px;
			}
		}
	}
	&.form__component--disabled{
		opacity: 0.5;
	}

	&.form__component--list{
		form{
			width: 100%;
		}
	}
	&.form__component--buttons{
		justify-content: flex-start;
		flex-wrap:wrap;
		.form__component__column{

		}

	}
	&.form__component--fieldset{
		align-items:center;
		margin-left: -9px;
		margin-right: -9px;
		.form__component__column{
			padding-left: 9px;
			padding-right: 9px;

			&--50{
				width: 50%;
			}
		}

		&--vertical{
			flex-direction: column;
			align-items:flex-start;
		}

	}

	&.form__component--mw{
		max-width: 560px;
	}

	&.form__component--search{
		.form__element{
			position: relative;
			input{
				padding-right: 48px;
				&:focus{
					~ .search-results{
						//display: block;
					}

					+ .form__element__icon{
						svg{
							path{
								fill:#bfe8e6;
							}
						}
					}
				}
			}
			.form__element__icon{
				position: absolute;
				width: 24px;
				height: 24px;
				right: 18px;
				top: 12px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border:0;
				outline: 0;
				background-color: transparent;
				padding: 0;
				svg{
					path{
						fill:#acacac;
					}
				}
			}
		}

		.search-results{
			position: absolute;
			width: 100%;
			top: 100%;
			border-left:1px solid #bfe8e6;
			border-right:1px solid #bfe8e6;
			border-bottom:1px solid #bfe8e6;
			display: none;
			z-index: 100;
			&__item{
				display: block;
				width: 100%;
				@include text-crop();
				padding-left: 18px;
				padding-right: 18px;
				padding-top: 9px;
				padding-bottom: 9px;
				cursor: pointer;
				position: relative;
				background-color: #fff;
				&:nth-child(odd){
					//background-color: rgba($lightgreen,0.1);
				}
				&:nth-child(even){
					//background-color: rgba($lightgreen,0.0);
				}
				&:hover,
				&:focus{
					background-color: #79bc41;
					font-weight: bold;
					color: #fff;

					&:after{
						background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' fill='%23ffffff'%3E%3Cpath d='M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z'/%3E%3C/svg%3E");
					}
				}
				&:after{
					width: 18px;
					height: 18px;
					background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' fill='%23acacac'%3E%3Cpath d='M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z'/%3E%3C/svg%3E");
					-webkit-background-size: contain;
					background-size: contain;
					background-repeat: no-repeat;
					transform:rotate(135deg);
					position: absolute;
					right: 18px;
					top: 10px;
				}
			}
		}

		.popup & {
			background: red;
		}

	}

	& + & {
		margin-top: $spacer * 1.5;
	}

	&--fieldset--vertical {
		.form__component__column + .form__component__column {
			margin-top: $spacer * .75;
		}
	}

	.form__element + .form__element {
		margin-top: $spacer * .75;
	}

}



.filter-form{
	.form__component,
	.form__element{
	}
	.form{
		margin-bottom: 0;
	}
	.form__component__column{
		a,button,span{
			margin-bottom: 9px !important;
		}
	}
}

.form__general-error-message{
	padding: 10px;
    border-left: 6px solid  $form-error-color;
    border-radius: 5px;
    background-color: #fae7e8;
    margin-bottom: 18px;
}
.form__label{
	width: 100%;
	@include text-crop();
	display: flex;
	transition: .3s;
	margin-bottom: 6px;
	font-weight: 700;
	+.form__error-message{
		margin-top: -3px;
		margin-bottom: 15px;
	}
}
.form__error-message{
	font-weight: bold;
	color: $form-error-color;
	width: 100%;
	@include text-crop($top-adjustment:-3px);
	margin-bottom: 12px;
}
.form__element{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	position: relative;
	&.form__element--checkbox-privacy{
		input[type=checkbox]+label{
			font-size: 12px;
			font-weight: 500;
		}
	}
	&.form__element--submit{
		button{
			outline:0;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: 0;
		}
	}
	&.form__element--notice{
		font-size: 12px;
		font-style: italic;
		font-weight: 300;

	}

	&.form__element--logo{
		input[type="radio"]{
			+label{
				&:before{
					top: calc(50% - 9px);
				}
				&:after{
					top: calc(50% - 4px);
				}
			}
		}
	}
	&.form__element--select{
		&:after{
			content:'';
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7px 0 7px;
			border-color: #0e1e3f transparent transparent transparent;
			right: 18px;
			top: 24px;
			pointer-events:none;
		}
	}
	&.form__element--switch{
		margin-bottom: 9px;
		&.form__element--switch--first{
			margin-top: 18px;

		}
		input[type=checkbox],
		input[type=radio]{
			&:checked{
				+ label{
					&:before{
						background-color: $darkgreen;
						border-color: $darkgreen;
					}
					&:after{
						right: 12px;
					}
				}
			}
			+ label{
				margin-right: 0;
				padding-left: 0;
				width: 100%;
				min-height:18px;
				line-height: 18px;
				padding-top: 0;
				&:before{
					width: 28px;
					height: 18px;
					border-radius:12px;
					background-color: #acacac;
					top: 0px;
					transition:linear .2s;
					border:0;
					right: 0px;
					left: auto;

				}
				&:after{
					width: 12px;
					height: 12px;
					background-color: #fff;
					border-radius:50%;
					opacity: 1;
					background-image: none;
					transition:linear .2s;
					top: 3px;
    				right: 3px;
					left: auto;
				}
			}

		}
	}

	&--autocomplete {
		.rbt {
			width: 100%;
		}
		&--actions {
			right: map-get($spacers, 2);
		}
	}
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

.isFormUpload {
   display: none;
}

.form__element__wrapper--bootstrapdatetime{
	.MuiFormControl-root{
		width: 100%;
	}
}

.dropdown-handle {
	cursor: pointer;
}

.MuiInput-root{
	&:before,
	&:after{
		display: none !important;
	}
}

.MuiInput-root .MuiInput-input{
	box-sizing: border-box;
}
.MuiChip-root + .MuiAutocomplete-input,
.MuiAutocomplete-tag + .MuiAutocomplete-input{
	margin-top: 8px;
}

.MuiAutocomplete-inputRoot input.MuiAutocomplete-input{
	min-width: 100% !important;
}

@media screen and (max-width:767px){

}

@include media-breakpoint-down(md) {
	.form__component.form__component--buttons {
		flex-direction: column;

		.form__component__column {
			width: 100%;

			a, button {
				width: 100%;
			}
		}

	}
}
