h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
}

h1{
	margin-bottom: 36px;
	@include fluid-type($min_width, $max_width, 32px, 48px);
	@include text-crop($line-height:1.2);
	color: #00a49a;
}

h2{
	@include fluid-type($min_width, $max_width, 27px, 34px);
	@include text-crop($line-height:1.2);
	margin-bottom: 36px;
	color: #00a49a;
}

h3{
	@include fluid-type($min_width, $max_width, 22px, 30px);
	@include text-crop($line-height:1.2);
	margin-bottom: 36px;
	font-weight: normal;
	color: #00a49a;
}


h4,
.introduction{
	@include fluid-type($min_width, $max_width, 18px, 22px);
	@include text-crop($line-height:1.5);
	margin-bottom: 24px;
	font-weight: bold;
	
	&.alt{
		text-transform: none;
		font-weight: 300;
	}
}

h5{

}

.tmce:not(.banner__content) {
	color: #000;
}

small{
	font-size: 12px;
}

a{
	color: inherit;
	text-decoration: none;
}
.tmce a {
    font-weight: 700;
    color: #19197c;
    text-decoration: none;
}
.tmce a.read-more {
    font-weight: normal;
    &::before {
        top: -3px;
    }
}
.tmce a.read-more--alt {
    &::before {
        top: 2px;
    }
}

.tmce a:not([class]):hover {
    text-decoration: underline;
}

blockquote{
	text-align: center;
	
	header{
	
	}
	footer{
		display: flex;
		align-items:center;
		justify-content: center;
		
		.blockquote__image{
			position: relative;
			width: 60px;
			height: 60px;
			overflow: hidden;
			border-radius:50%;
			flex-shrink: 0;
			flex-grow:0;
			margin-right: 18px;
			
			img{
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				object-fit:cover;
				
			}
			
		}
		
		.blockquote__text{
			text-align: left;
		}
		
		+ a{
			margin-top: 18px;
		}
	}
	h3{
		color: #82b935;
		font-weight: 600;
		@include text-crop($line-height:1.5, $top-adjustment:0px, $bottom-adjustment:0px);
	}
}




::selection {
	background-color: $darkgreen;
	color: #fff;
}

ol {
  list-style-position: outside;
}

@include media-breakpoint-down(md) {
	h1, h2, h3 {
		margin-bottom: 24px;
	}
}


