.footer{
	color: #acacac;
	position: relative;
	z-index: 1;
	height: 72px;
	&__item{
		display: flex;
		flex-direction: column;
		align-items:center;
		width: 100%;
		position: relative;

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			background: $border-color;
			position: absolute;
			top: 0;
			left: 0;
		}

		&__icon {
			position: relative;

			i {
				font-size: 1.5rem;
			}

			&__notification {
				position: absolute;
				right: -4px;
				top: -4px;
				width: 16px;
				height: 16px;
				background-color: #ff0000;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				font-size: 10px;
				font-weight: bold;

			}
		}
		&__label{
			font-size: 12px;
			margin-top: auto;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}

		&--current::before {
			background: $primary;
			height: 2px;
		}

	}
}

.footer__item--more{
	display: none !important;
}
