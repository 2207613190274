@use "sass:math";

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}


@mixin text-crop($line-height: 1.3, $top-adjustment: 0px, $bottom-adjustment: 0px) {
    // Configured in Step 1
    $top-crop: 9;
    $bottom-crop: 9;
    $crop-font-size: 36;
    $crop-line-height: 1.2;

    // Apply values to calculate em-based margins that work with any font size
    $dynamic-top-crop: math.div(max(($top-crop + ($line-height - $crop-line-height) * math.div($crop-font-size, 2)), 0), $crop-font-size);
    $dynamic-bottom-crop: math.div(max(($bottom-crop + ($line-height - $crop-line-height) * math.div($crop-font-size, 2)), 0), $crop-font-size);

    // Mixin output
    line-height: $line-height;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 0;
        width: 0;
    }

    &::before {
        margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
    }

    &::after {
        margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
    }
}

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@function vh($quantity) {
	@return calc(var(--vh, 1vh) * #{$quantity});
}
