/*
Theme file. Bootstrap 5.1.3 grid, containers & utilities included.
 */

@import "scss/fonts";


// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "variables"; // override bootstrap defaults
@import "../../node_modules/bootstrap/scss/variables";

// Merge Bootstrap variables with custom variables
$theme-colors: map_merge($theme-colors, (
        "blue" : $blue,
        "blue-dark" : $darkblue,
        "grey" : #ccc,
        "grey-dark" : $form-fields-border-color,
));

//@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";
@import "scss/base/mixins"; // override bootstrap mixins

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/images";
@import "scss/base/reset";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/badge";

@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/utilities/api"; // use those amazing bootstrap utilities
@import "scss/base/typography";
@import "scss/base/dropdown";
@import "scss/base/alerts";

@import "scss/lib/slick/slick";
//@import "scss/lib/slick/slick-theme";
//@import "scss/lib/scrollhint/scrollhint";


@import "scss/icons";
@import "scss/global";


@import "scss/components/navbar";
@import "scss/components/header";
@import "scss/components/buttons";
@import "scss/components/content";
@import "scss/components/form";
@import "scss/components/popup";
@import "scss/components/footer";

@import "scss/components/login";

@import "scss/base/spinner";
@import "scss/base/utilities";
