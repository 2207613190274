.navbar{
    background-color: #ffffff;
    position:-webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    position: fixed;
	&:after{
		content:'';
		width: 100%;
		height: 2px;
		background: linear-gradient(to right, #8FD26B 0%, #00A49B 100%);
		display: block;
	}
	&__wrapper{
		display: flex;
		align-items:center;
		height: 82px;
	}
	&__identity{
		width: 48px;
		height: 48px;
		font-size: 16px;
		text-transform: uppercase;
		color: #fff;
		letter-spacing: 1px;
		display: flex;
		justify-content: center;
		align-items:center;
		margin-right: 18px;
		border-radius: 50%;
		overflow: hidden;
		flex-grow: 0;
		flex-shrink: 0;
		background: linear-gradient(to right, #8FD26B 0%, #00A49B 100%);
		font-weight: bold;
	}
	&__company{
		font-weight: bold;
		font-size: 16px;
	}
}

.navigation{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 73px;
	z-index: 101;
	display: flex;
	pointer-events:none;
	transition:.15s;
	&--visible{
		background-color: rgba(#000,0.35);
		transition-delay:.1s;
		pointer-events:auto;
		.navigation__side{
			transform:translateX(0);
			transition-delay:0;
		}
	}
	&__inner{
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		flex-grow:1;
	}
	&__side{
		display: flex;
		flex-direction: column;
		flex-grow:1;
		flex-shrink:1;
		width: 100vw;
		max-height: calc((var(--vh, 1vh) * 100) - 73px);
        overflow-y: auto;
        transform:translateX(100vw);
        
        transition:.2s;
        transition-delay:.15s;
        will-change:transform;
	}
	&__top{
		background-color: #f8f8f8;
	    position: sticky;
	    top: 0;
	    left: 0;
	    right: 0;
	    z-index: 100;
	    cursor: pointer;
	    position: fixed;
	    &[href]{
	    	display: block;
	    }
	    &__label{
	    	display: flex;
	    	align-items:center;
	    	
	    	&--right{
	    		margin-left: auto;
	    	}
	    }
		&:after{
			content:'';
			width: 100%;
			height: 3px;
			background: linear-gradient(to right, #8FD26B 0%, #00A49B 100%);
			display: block;
		}
		
		&__icon{
			margin-right: 18px;
			margin-left: 24px;
			
			svg{
				width: 24px;
				height: auto;
				transform:scaleX(-1);
				path{
					fill:#acacac;
				}
			}
		}
		&__label{
		    display: flex;
		    align-items:center;
		    min-height:82px;
		    font-weight: bold;
			font-size: 16px;
			padding-right: 24px;
		}
	}
	&__center{
		flex-grow:1;
		flex-shrink: 0;
		background-color: #fff;
		
	}
	&__nav{
		display: flex;
		flex-direction:column;
		&__item{
			display: flex;
			flex-direction:column;
			background-color: #fff;
			&__link{
				display: flex;
				align-items:center;
				font-weight: 700;
				padding-left: 24px;
				padding-right: 24px;
				padding-top: 12px;
				padding-bottom: 12px;
				border-bottom: 1px solid rgba(#000,0.1);
				
				.navigation__nav--alt &{
					position: relative;
					&:after{
						content:'';
						position: absolute;
						right: 24px;
						top: 12px;
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23acacac'%3E%3Cpath d='M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z'/%3E%3C/svg%3E");
						background-position: right center;
						background-repeat: no-repeat;
						width: 24px;
						height: 24px;
						-webkit-background-size: contain;
						background-size: contain;
					}
				}
			}
			&:nth-child(odd){
				a{
					background-color: rgba($lightgreen,0.1);
				}
			}
			&:nth-child(even){
				a{
					background-color: rgba($lightgreen,0.0);
				}
			}
			&__icon{
				width: 24px;
				height: 24px;
				margin-right: 18px;
				position: relative;
				display: block;
				color: #acacac;
				font-size: 1.5rem;

				svg{
					width: 24px;
					height: auto;
					max-width: 24px;
					max-height: 24px;
					display: block;
					path,
					circle{
						fill:#acacac;
					}
				}
				
				&__notification{
					position: absolute;
					right: -4px;
					top: -4px;
					width: 16px;
					height: 16px;
					background-color: #ff0000;
					color: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					font-size: 10px;
					font-weight: bold;
				}
			}
		}
	}
	&__bottom{
		
	}
}