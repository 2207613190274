.login{
	min-height: vh(100);
	background-color: #fff;
	&__top{
		background-color: #f8f8f8;
		padding-top: 12px;
		padding-bottom: 12px;
		
		&--bb{
			position: relative;
			margin-bottom: 3px;
			&:after{
				content:'';
				position: absolute;
				left: 0;
				right: 0;
				bottom: -3px;
				height: 3px;
				background: linear-gradient(to right, #8fd26b 0%, #00a49b 100%);
			}
		}
	}
	&__header{
	    background: linear-gradient(to right, #8fd26b 0%, #00a49b 100%);
	    
	    &__row{
	    	height: 40vw;
			max-height: 320px;
	    }
	    
	    h1{
	    	color: #fff;
	    }
	}
	&__form{
		padding-top: 24px;
	}
}