@use "sass:math";

.notifications{
	display: flex;
	flex-direction: column;
	&__list{
		display: flex;
		flex-direction: column;

		&__item{

		}
	}
}

.notification{
	border-bottom: 1px solid rgba(#000,0.25);
	position: relative;
	padding-left: 6px;
	&:before{
		content:'';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: #acacac;
		width: 6px;
		z-index: 2;
	}
	&--unread{
		&:before{
			background-color: $darkgreen;
		}
		.notification__inner{
			font-weight: bold;
		}
		.notification__arrow{
			svg{
				path{
					fill:$darkgreen;
				}
			}
		}
	}
	&__inner{
		display: flex;
		position: relative;
		background-color: #fff;
		z-index: 1;
		will-change:transform;
		transition: transform linear .25s;
		user-select: none;
		&:after{
			content:'';
			width: 100%;
			height: 40%;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			opacity: 0.04;
			bottom: 0;
			left: 0;
			right: 0;
			pointer-events:none;
			position: absolute;
		}
		&--moveright{
			transform:translateX(25%);
		}
		&--moveleft{
			transform:translateX(-37%);
		}
	}
	&__left{
		flex-grow:1;
		display: flex;
		flex-direction: column;
		padding: 12px;
	}
	&__right{
		display: flex;
		flex-direction: column;
		padding: 12px;
		justify-content: center;
	}

	&__date{
		font-size: 11px;
	}
	&__subject{

	}
	&__type{
		padding-left: 18px;

		&--image{
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23acacac'%3E%3Cpath d='M5 4h-3v-1h3v1zm8 6c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-5v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm13 4c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z'/%3E%3C/svg%3E");
			background-size: 12px auto;
			background-position: left center;
			background-repeat: no-repeat;
		}
	}
	&__arrow{
		width: 24px;
		height: auto;

		svg{
			path{
				fill:#acacac;
			}
		}
	}
	&__actions{

		&__left{
			display: block;
			position: absolute;
			height: 100%;
			left: -100%;
			transition: left linear .25s;
			top: 0;
		}
		&__right{
			display: flex;
			position: absolute;
			top: 0;
			right: -100%;
			height: 100%;
			transition: right linear .25s;
			border-left:1px solid $border-color;
		}

		img {
			display: block;
			height: 100%;
			width: 100%;
			max-width: 100px;
			object-fit: cover;
		}

		&--moveleft {
			.notification__actions__right {
				right: 0;
				@include media-breakpoint-down(md) {
					right: -(math.div($grid-gutter-width, 2));
				}
			}
		}
		&--moveright {
			.notification__actions__left {
				left: 0;
				@include media-breakpoint-down(md) {
					left: -(math.div($grid-gutter-width, 2));
				}

			}
		}
		&__action{
			color: $white;
			position: relative;
			z-index: 1;
			min-width: 72px;

			&__wrap {
				width: 100%;
				&__label {
					font-size: .8rem;
					transition: background-color linear .15s;
					white-space: nowrap;
				}

			}

			&--more{
				background-color: #ffffff;
				position: relative;
				color: #18a09b;
				&:hover,
				&:focus{
					//background-color: darken(#acacac,10%);
				}

			}
			&--call{
				background-color: $lightgreen;

				&:hover,
				&:focus{
					background-color: darken($lightgreen,10%);
				}
			}
			&--mail{
				background-color: $darkgreen;

				&:hover,
				&:focus{
					background-color: darken($darkgreen,10%);
				}
			}

			&--gallery{
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				img{
					width: 100%;
					height: 100%;
					object-fit:cover;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}

		&__wrap {

		}

	}
	&__submenu{
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(#000,0);
		display: flex;
		justify-content:center;
		align-items:center;
		height: 100%;
		width: 100%;
		//padding: 5vw;
		position: fixed;
		z-index: 100;
		transition:.15s;
		opacity: 0;
		pointer-events:none;
		&--visible{
			display: flex;
			background-color: rgba(#000,0.65);
			opacity: 1;
			pointer-events:auto;
			z-index: 9999999;
			.notification__submenu__inner{
				transform:scale(1);

			}
		}
		&__inner {
			height: 100%;
			background-color: $light;
			transition: .2s;
		}
		&__top{
			font-size: 20px;
		}

		&__option {
			color: $primary;
			border-radius: 6px;

			& + .notification__submenu__option {
				margin-top: 9px;
			}

			&:last-child{
				//border-bottom: 0;
			}
			&--price{
				padding-left: 36px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(24, 160, 155)' d='M12.000,15.500 C9.490,15.500 7.320,14.080 6.240,12.000 L12.000,12.000 L12.000,10.000 L5.580,10.000 C5.530,9.670 5.500,9.340 5.500,9.000 C5.500,8.660 5.530,8.330 5.580,8.000 L12.000,8.000 L12.000,6.000 L6.240,6.000 C7.320,3.920 9.500,2.500 12.000,2.500 C13.610,2.500 15.090,3.090 16.230,4.070 L18.000,2.300 C16.410,0.870 14.300,0.000 12.000,0.000 C8.080,0.000 4.760,2.510 3.520,6.000 L-0.000,6.000 L-0.000,8.000 L3.060,8.000 C3.020,8.330 3.000,8.660 3.000,9.000 C3.000,9.340 3.020,9.670 3.060,10.000 L-0.000,10.000 L-0.000,12.000 L3.520,12.000 C4.760,15.490 8.080,18.000 12.000,18.000 C14.310,18.000 16.410,17.130 18.000,15.700 L16.220,13.930 C15.090,14.910 13.620,15.500 12.000,15.500 Z'/%3E%3C/svg%3E");
			}
			&--buy{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='19px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(24, 160, 155)' d='M12.458,17.100 L8.304,13.050 L9.686,11.700 L12.458,14.400 L17.514,9.450 L18.895,10.800 L12.458,17.100 ZM15.672,3.600 L13.830,3.600 L13.830,6.300 L4.621,6.300 L4.621,3.600 L2.779,3.600 L2.779,16.200 L8.304,16.200 L8.304,18.000 L2.779,18.000 C1.766,18.000 0.937,17.190 0.937,16.200 L0.937,3.600 C0.937,2.610 1.766,1.800 2.779,1.800 L6.628,1.800 C7.015,0.756 8.028,0.000 9.225,0.000 C10.422,0.000 11.435,0.756 11.822,1.800 L15.672,1.800 C16.685,1.800 17.514,2.610 17.514,3.600 L17.514,8.100 L15.672,8.100 L15.672,3.600 ZM9.225,1.800 C8.719,1.800 8.304,2.205 8.304,2.700 C8.304,3.195 8.719,3.600 9.225,3.600 C9.732,3.600 10.146,3.195 10.146,2.700 C10.146,2.205 9.732,1.800 9.225,1.800 Z'/%3E%3C/svg%3E");
				padding-left: 36px;
			}
			&--relation{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='14px'%3E%3Cpath fill-rule='evenodd' fill='rgb(24, 160, 155)' d='M11.455,7.454 C13.263,7.454 14.728,5.990 14.728,4.181 C14.728,2.373 13.263,0.908 11.455,0.908 C9.647,0.908 8.182,2.373 8.182,4.181 C8.182,5.990 9.647,7.454 11.455,7.454 ZM11.455,2.545 C12.355,2.545 13.092,3.281 13.092,4.181 C13.092,5.081 12.355,5.818 11.455,5.818 C10.555,5.818 9.819,5.081 9.819,4.181 C9.819,3.281 10.555,2.545 11.455,2.545 ZM11.455,9.091 C9.270,9.091 4.909,10.187 4.909,12.364 L4.909,14.000 L18.001,14.000 L18.001,12.364 C18.001,10.187 13.640,9.091 11.455,9.091 ZM6.546,12.364 C6.726,11.774 9.254,10.727 11.455,10.727 C13.664,10.727 16.201,11.783 16.364,12.364 L6.546,12.364 ZM4.091,9.909 L4.091,7.454 L6.546,7.454 L6.546,5.818 L4.091,5.818 L4.091,3.363 L2.455,3.363 L2.455,5.818 L-0.000,5.818 L-0.000,7.454 L2.455,7.454 L2.455,9.909 L4.091,9.909 Z'/%3E%3C/svg%3E");
				padding-left: 36px;
			}
			&--delete{
				color: #ff3b30;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff3b30' viewBox='0 0 24 24'%3E%3Cdefs/%3E%3Cpath d='M3 6v18h18V6H3zm5 14a1 1 0 01-2 0V10a1 1 0 012 0v10zm5 0a1 1 0 01-2 0V10a1 1 0 012 0v10zm5 0a1 1 0 01-2 0V10a1 1 0 012 0v10zm4-18v2H2V2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2H22z'/%3E%3C/svg%3E");
				padding-left: 36px;
				-webkit-background-size: 18px auto;
				background-size: 18px auto;
				border-color:#ff3b30;
			}
			&--phone{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%2300a49a'%3E%3Cpath d='M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z'/%3E%3C/svg%3E");
				padding-left: 36px;
			}
			&--email{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%2300a49a'%3E%3Cpath d='M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z'%3E%3C/path%3E%3C/svg%3E");
				padding-left: 36px;
			}
			&--photo{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(24, 160, 155)' d='M16.000,2.000 L16.000,16.000 L2.000,16.000 L2.000,2.000 L16.000,2.000 M16.000,0.000 L2.000,0.000 C0.900,0.000 -0.000,0.900 -0.000,2.000 L-0.000,16.000 C-0.000,17.101 0.900,18.001 2.000,18.001 L16.000,18.001 C17.100,18.001 18.000,17.101 18.000,16.000 L18.000,2.000 C18.000,0.900 17.100,0.000 16.000,0.000 ZM11.140,8.860 L8.140,12.730 L6.000,10.140 L3.000,14.000 L15.000,14.000 L11.140,8.860 Z'/%3E%3C/svg%3E");
				padding-left: 36px;
			}
		}
	}
}

.notification__submenu{
	align-items:flex-end;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
}
.notification__submenu--visible .notification__submenu__inner{
	transform: translateY(0);
}
.notification__submenu__inner{
	width: 100%;
	transform-origin:bottom center;
	border-radius: 0;
	transform: translateY(100%);
    transition: .35s;
    transition-delay:.2s;
    background-color: #fff;
}

.content{
	padding: $spacer * 1.5 0;

	> .container > *:last-child {
		margin-bottom: 0;
	}

	+ .content:not(.content--bt):not(.content--bb){
		padding-top: 0;
	}

	&:not(.content--bt):not(.content--bb){
		+ .content--bt,
		+ .content--bb{
			padding-top: 0;
		}


	}


	&--bb{
		border-bottom:1px solid $border-color;

		+ .content:not(.content--bt):not(.content--bb){
			padding-top: 24px;
		}

		&--primary{
			border-bottom: 0;

			&:after{
				content: '';
			    width: 100%;
			    height: 3px;
			    background: linear-gradient(to right, #8FD26B 0%, #00A49B 100%);
			    display: block;
			    position: absolute;
			    bottom: 0;
			}
		}
	}
	&--bt{
		border-top:1px solid rgba(#000,0.1);

		+ .content:not(.content--bt):not(.content--bb){
			padding-top: 24px;
		}
	}
}

.contact-list{
	display: flex;
	flex-direction: column;
	margin-top: 12px;

	&__item{
		display: flex;
		margin-bottom: 9px;
		&:last-child{
			margin-bottom: 0;
		}
		a{
			display: flex;
			align-items:center;
		}

		svg{
			display: block;
			path{
				fill:$darkgreen;
			}
		}
		&__icon{
			width: 24px;
			margin-right: 18px;
			svg{
				max-width: 24px;
				max-height: 24px;
			}
		}
		&__label{
			font-weight: 700;
			color: $darkgreen;
		}
	}
}


.results{
	scroll-behavior: smooth;
	background: repeating-linear-gradient(45deg, #ffffff, #f1f8ec 10px, #fff 10px, #fff 20px);

	&__top{
		position: sticky;
		top: 0px;
		background-color: #fff;
		z-index: 100;
		&:after{
			content:'';
			width: 100%;
			height: 3px;
			background: linear-gradient(to right, #8FD26B 0%, #00A49B 100%);
			display: block;
		}
	}
	&__filters{
		display: flex;
		align-items:center;
		padding-bottom: 18px;
		padding-top: 18px;
		&__label{
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23acacac'%3E%3Cpath d='M1 0h22l-9 15.094v8.906l-4-3v-5.906z'/%3E%3C/svg%3E");
			padding-left: 27px;
			background-position: left center;
			-webkit-background-size: auto 18px;
			background-size: auto 18px;
			background-repeat: no-repeat;
			margin-right: 24px;
		}
		&__wrapper{
			display: flex;
			align-items:center;
			max-width: 100%;
			overflow-y: auto;
		}
		&__filter{
			white-space: nowrap;
			margin-right: 24px;

			&--active{
				text-decoration: underline;
				font-weight: 700;
			}
		}
	}
	&__overview{
		&__list{
			&--narrow{
				.results__overview__list__item:not(.results__overview__list__item--alt){
					display: none;
				}
				.results__overview__list__item.results__overview__list__item--alt{
					display: block;
				}
			}
			&__item{
				padding: 18px;
				background-color: #fff;
				display: flex;
				flex-direction: column;
				margin-bottom: 12px;
				cursor: pointer;
				position: relative;
				border-bottom:1px solid $border-color;
				overflow-x: hidden;
				&--alt{
					padding: 0;
					padding-right: 0;
					margin-bottom: 0;
				}
				&:last-child{
					margin-bottom: 0;
					box-shadow:
					  0 2.8px 2.2px rgba(0, 0, 0, 0.003),
					  0 6.7px 5.3px rgba(0, 0, 0, 0.004),
					  0 12.5px 10px rgba(0, 0, 0, 0.005),
					  0 22.3px 17.9px rgba(0, 0, 0, 0.006),
					  0 41.8px 33.4px rgba(0, 0, 0, 0.007),
					  0 100px 80px rgba(0, 0, 0, 0.01)
					;
				}
				&__inner{
					padding: 12px;
					padding-left: 24px;
					padding-right: 24px;
					padding-bottom: 6px;
					padding-top: 6px;
					font-size: 12px;
					margin-bottom: 0;
				    user-select: none;
				    will-change: transform;
					z-index: 1;
					position: relative;
					transition: transform linear .25s;

					&--moveright{
						transform: translateX(#{100px + math.div($grid-gutter-width, 2)});
						@include media-breakpoint-down(md) {
							transform: translateX(100px);
						}
					}

					&--moveleft {
						transform: translateX(-37%);
					}
				}
				&__title{
					font-weight: 700;
					margin-bottom: 6px;
					//font-size: 14px;

					&.results__overview__list__item__price {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23999' viewBox='0 0 24 24'%3E%3Cdefs/%3E%3Cpath d='M17.728 20.517c-3.488 0-5.613-2.461-6.443-5.517H18l.333-2h-7.398a12.253 12.253 0 01-.014-2h7.746L19 9h-7.755c.786-3.106 2.855-5.626 6.154-5.626 1.133 0 2.391.203 3.836.62L22 .832C20.146.28 18.384 0 16.756 0 10.797 0 7.215 4.152 6.162 9H4l-.333 2H5.87a14.355 14.355 0 00-.007 2h-2.53L3 15h3.145c1.033 4.848 4.664 9 11.085 9 1.5 0 3.004-.276 4.476-.821l-.883-3.23c-1.048.378-2.088.568-3.095.568z'/%3E%3C/svg%3E");
						background-position: left center;
						background-repeat: no-repeat;
						padding-left: 18px;
						background-size: auto 12px;
						margin-bottom: 0;

					}
					&.results__overview__list__item__photo{
						margin-bottom: 0;
					}

				}
				&__details{
					display: flex;
					margin-left: -6px;
					margin-right: -6px;
					margin-bottom: 12px;
					&__value{
						padding: 6px;
						background-color: #fff;
						margin-left: 6px;
						margin-right: 6px;
						border:1px solid rgba(#000,0.1);
					}
				}
				&__row{
					display: flex;
					align-items:center;
					justify-content: space-between;
					margin-bottom: 6px;
					margin-bottom: 0;
					&:last-child{
						margin-bottom: 0;
					}
				}
				&__company{
					font-weight: 700;
				}
				&__text{
					overflow: hidden;
					white-space: nowrap;
					text-overflow:ellipsis;
				}
				&__photo{
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13px' height='11px'%3E%3Cpath fill-rule='evenodd' fill='rgb(153, 153, 153)' d='M2.281,2.283 L10.918,2.283 C12.022,2.283 12.918,3.178 12.918,4.283 L12.918,8.873 C12.918,9.977 12.022,10.873 10.918,10.873 L2.281,10.873 C1.176,10.873 0.281,9.977 0.281,8.873 L0.281,4.283 C0.281,3.178 1.176,2.283 2.281,2.283 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(153, 153, 153)' d='M4.388,0.962 L8.885,0.962 C9.469,0.962 9.943,1.436 9.943,2.020 C9.943,2.605 9.469,3.078 8.885,3.078 L4.388,3.078 C3.804,3.078 3.330,2.605 3.330,2.020 C3.330,1.436 3.804,0.962 4.388,0.962 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(241, 248, 236)' d='M6.502,2.972 C8.293,2.972 9.744,4.423 9.744,6.214 C9.744,8.004 8.293,9.456 6.502,9.456 C4.712,9.456 3.260,8.004 3.260,6.214 C3.260,4.423 4.712,2.972 6.502,2.972 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(153, 153, 153)' d='M6.501,4.671 C7.356,4.671 8.050,5.365 8.050,6.220 C8.050,7.076 7.356,7.769 6.501,7.769 C5.646,7.769 4.952,7.076 4.952,6.220 C4.952,5.365 5.646,4.671 6.501,4.671 Z'/%3E%3C/svg%3E");
					background-position: left center;
					background-repeat: no-repeat;
					padding-left: 18px;
					background-size: 12px auto;
					margin-bottom: 0;

				}
				&__price{


				}
				&__buy{
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23999' viewBox='0 0 24 24'%3E%3Cdefs/%3E%3Cpath d='M22 2v22H2V2h3C6.23 2 7.181.916 8 0h8c.82.916 1.771 2 3 2h3zM11 3a1 1 0 102 0 1 1 0 00-2 0zm9 1h-4l-2 2h-3.897L8 4H4v18h16V4zM7 13.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64C14.859 11.87 13.291 13.95 11.033 18 9.64 16.36 8.711 15.314 7 13.729z'/%3E%3C/svg%3E");
					background-position: left center;
					background-repeat: no-repeat;
					padding-left: 18px;
					background-size: 12px auto;
					margin-bottom: 0;

				}
				&__relation{
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23999999'%3E%3Cpath d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z'/%3E%3C/svg%3E");
					background-position: left center;
					background-repeat: no-repeat;
					padding-left: 18px;
					background-size: 12px auto;
					margin-bottom: 0;

				}
				&__user{
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%2300a39a'%3E%3Cpath d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z'/%3E%3C/svg%3E");
					background-position: left center;
					background-repeat: no-repeat;
					padding-left: 18px;
					background-size: 12px auto;
					margin-bottom: 0;
				}

			}
		}
	}
}

.bb-primary{
	margin-bottom: 3px;
	position: relative;
	&:after{
		content:'';
		width: 100%;
		height: 3px;
		background: linear-gradient(to right, #8FD26B 0%, #00A49B 100%);
		display: block;
		position: absolute;
		bottom: -3px;
		left: 0;
	}
}

.product-carousel {
	position: relative;

	.slick-track {
		display: flex;
	}

	.slick-slide {
		float: none;
		//display: flex;
		//flex-direction: column;
	}

	.slick-list {
		width: 100%;
	}

	&__slide {
		img {
			width: 100%;
		}

	}

	.slick-slide {
		position: relative;
		height: auto;
	}

	.slick-slide > div {
		width: 100%;
		height: 100%;
		padding: 0 math.div($spacer, 2);
	}


}

.gallery {
	position: relative;
	//padding-left: 150px + $spacer;
	//min-height: 150px;

	&__images {
		width: 100%;
	}

	&__add {
		position: relative;
		height: 100%;
		min-height: 100px;
		@include media-breakpoint-up(md) {
			min-height: 150px;
		}
		//position: absolute;
		//left: 0;
		//width: 150px;
		//height: 100%;

		input[type="file"] {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.image__wrapper {

		img {
			height: 100px;
			@include media-breakpoint-up(md) {
				height: 150px;
			}
			width: 100%;
			object-fit: cover;
		}

		&--remove {
			position: absolute;
			padding: .5rem;
			color: $danger;
			background: color-contrast($danger);
			right: .5rem;
			top: .5rem;
			background: $white;
			cursor: pointer;
		}

	}

}

.tabs{
	display: flex;
	border-bottom: 1px solid rgba(#000,0.1);
	&__tab{
		flex:1;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items:center;
		background-color: #f8f8f8;
		&--current{
			color: #fff;
			font-weight: 700;
			background-color: $lightgreen;
		}
	}
}

.filters{

	&__label{
		font-weight: bold;
		cursor: pointer;
	}
	&__form{
		//padding-top: 24px;
		//display: none;
		/* position: fixed;
	    z-index: 100;
	    background: #fff;
	    width: 100%;
	    height: 100%;
	    left: 0;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    padding: 24px; */
	}
}
