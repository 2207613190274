.dropdown-menu {
  background: white;
  position: relative;
  z-index: 999;
  border-left: 1px solid $border-color-checkbox;
  border-right: 1px solid $border-color-checkbox;
  border-bottom: 1px solid $border-color-checkbox;

  .dropdown-item {
    display: block;
    padding: .75rem 1rem;

    &.active {
      background: #8FD26B;
      color: $white;

      .icon {
        color: inherit;
      }

    }

    .icon {
      color: $border-color-checkbox;
    }

    .property {
      display: inline-block;
      padding: .25rem .5rem;
      border: 1px solid $border-color-checkbox;
    }

  }

}