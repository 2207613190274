header {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 71px;
  background-color: #fff;

  .border-gradient {
    position: absolute;
    bottom: 0;
  }

  .header-top-label {
    font-size: 1.25rem;
  }

}

.logo img {
  height: 40px;
  width: auto;
}