.bg-custom-gradient {
  background: linear-gradient(to right, #8FD26B 0%, #00A49B 100%);
}

.border-gradient {
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #8FD26B 0%, #00A49B 100%);
  display: block;
}

.text-white {
  color: white;
}

.icon-flip {
  display: inline-block;
  transform: rotate(180deg);
}

.c-primary {
  @extend .text-primary;
}

.c-secondary {
  @extend .text-secondary;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

.tt-n {
  text-transform: none;
}