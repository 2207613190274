html {
  padding: 0 !important;
  scroll-behavior: smooth;
  font-size: 14px;
  -webkit-text-size-adjust: 100%;

  &.side-menu-active {
    overflow: hidden;

  }
}

body {
  	padding-top: 72px;
  	padding-bottom: 72px;
	line-height: 1.5;
  font-size: 100%;

  	&.template-login {
    	padding-bottom: 0;
  	}
    > .col{
      .image__wrapper--actions{
        pointer-events: none;
        opacity: 0;
      }
    }
}

#appAlerts {
  z-index: 3;
}

.outer-wrapper {
  transition: transform ease 0.3s;
}

.block {
  position: relative;
}

a {
  outline: 0;
}

.notification-bar {

  padding-top: 12px;
  padding-bottom: 12px;

  &--succes {
    background-color: $darkgreen;

    .notification-bar__icon {

    }

    .notification-bar__label {
      color: #fff;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__icon {
    margin-right: 18px;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  &__label {
    font-weight: bold;
    padding-top: 4px;
  }
}

.pwa {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  color: #bbbbbb;
  font-size: 12px;
  transform: translateY(100%);
  transition: .3s;
  z-index: 3;

  &--visible {
    transform: translateY(0);
  }

  &__logo {
    width: 48px;
    height: 48px;
    background-image: url(../../img/assets/favicons/icon-144x144.png);
    -webkit-background-size: contain;
    background-size: contain;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__message {
    padding-left: 15px;

    &__title {
      font-size: 16px;
    }

    &__content {
      span.icon {
        width: 12px;
        height: 18px;
        background-image: url(../../img/assets/icons/action-icon-ios7.png);
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        margin-left: 3px;
        margin-right: 3px;
        position: relative;
        top: 2px;
      }
    }
  }

  &__close {
    width: 12px;
    height: 12px;
    background-image: url(../../img/assets/icons/icon-close.svg);
    background-size: 12px 12px;
    position: absolute;
    right: 15px;
    top: 15px;
    background-repeat: no-repeat;
  }

}

.page-header {
  &.size-lg {
    height: 20vh;
  }
}

.bg-white {
  background: $white !important;
}

@include media-breakpoint-down(md) {
  .mobile {
    font-size: 14px;
  }

  [data-image-ratio-small] {
    object-fit: cover;
    width: 100%;
  }

  [data-image-ratio-small="16:9"] {

    height: calc(56.25vw - 72px);
    min-height: calc(56.25vw - 72px);
    max-height: calc(56.25vw - 72px);

  }
  [data-image-ratio-small="4:3"] {
    height: calc(75vw - 72px);
    min-height: calc(75vw - 72px);
    max-height: calc(75vw - 72px);

  }
  [data-image-ratio-small="1:1"] {
    height: calc(100vw - 72px);
    min-height: calc(100vw - 72px);
    max-height: calc(100vw - 72px);

  }

  [data-image-position-small="top"] {
    object-position: top;
  }
  [data-image-position-small="center"] {
    object-position: center;
  }
  [data-image-position-small="bottom"] {
    object-position: bottom;
  }

  [data-text-align-small="center"] {
    text-align: center;
  }

}
