/**
Font files are rendered with https://icomoon.io/app/#/select/font
 */
$icon-sizes: (
        md: 2.5em,
        lg: 3.5em
);

@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icons/icomoon.eot?n2u4o9');
  src:  url('../../fonts/icons/icomoon.eot?n2u4o9#iefix') format('embedded-opentype'),
  url('../../fonts/icons/icomoon.ttf?n2u4o9') format('truetype'),
  url('../../fonts/icons/icomoon.woff?n2u4o9') format('woff'),
  url('../../fonts/icons/icomoon.svg?n2u4o9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.25em;
  width: 1.25em;
  height: 0.75em;
  display: inline-block;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @each $name, $size in $icon-sizes {
    &.icon-#{$name} {
      font-size: $size;
      height: auto;
    }
  }

  &::before {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
  }

}


.icon-arrow-right:before {
  content: "\e900";
}
.icon-camera:before {
  content: "\e901";
}
.icon-currency-euro:before {
  content: "\e902";
}
.icon-documents:before {
  content: "\e903";
}
.icon-envelope:before {
  content: "\e904";
}
.icon-handshake:before {
  content: "\e905";
}
.icon-house:before {
  content: "\e906";
}
.icon-leaf:before {
  content: "\e907";
}
.icon-price-request:before {
  content: "\e908";
}
.icon-relation-request:before {
  content: "\e911";
}
.icon-search:before {
  content: "\e912";
}
.icon-mail-envelope-closed:before {
  content: "\e909";
}
.icon-mail-envelope-closed1:before {
  content: "\e90a";
}
.icon-clipboard-edit:before {
  content: "\e90b";
}
.icon-clipboard-edit1:before {
  content: "\e90c";
}
.icon-clipboard-download:before {
  content: "\e90d";
}
.icon-clipboard-download1:before {
  content: "\e90e";
}
.icon-clipboard-checked:before {
  content: "\e90f";
}
.icon-clipboard-checked1:before {
  content: "\e910";
}
