.button{
	display: inline-flex;
	justify-content: space-between;
	align-items:center;
	height: 48px;
	font-weight: bold;
	transition:.2s;
	padding-left: 12px;
	padding-right: 12px;
	outline:0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border:0;
	font-size: inherit;
	border-radius:6px;
	font-weight: 500;
	letter-spacing:0.5px;
	&--primary{
	    background-color: #00a49a;
	    color: #fff;
	    
	    &:hover{
	    	background-color: #00a49a;
	    }
	}
	&--grey{
	    background-color: #f8f8f8;
	    color: inherit;
	    border: 1px solid #E5E5E5;
	    &:hover{
	    	//background-color: #00a49a;
	    }
	    
	    &.button--readmore{
	    	&:after{
    		    background-position: -618px -150px;
	    	}
	    }
	}
	&--readmore{
		&:after{
			content:'';
			margin-left: 24px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='16px'%3E%3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M8.000,-0.000 L6.590,1.410 L12.170,7.000 L-0.000,7.000 L-0.000,9.000 L12.170,9.000 L6.590,14.590 L8.000,16.000 L16.000,8.000 L8.000,-0.000 Z'/%3E%3C/svg%3E");
			background-position: right center;
			background-repeat: no-repeat;
			width: 16px;
			height: 16px;
			
		}
	}
	&--pill{
		height: 36px;
		border-radius: 18px;
		font-size: 12px;
		text-transform: uppercase;
	}
}

.read-more{
    color: #4285f4;
    font-size: 14px;
    font-weight: 700;
    float: left;
    display: block;
    width: 100%;
    padding-left: 20px;
    position: relative;
    
    &:before{
    	content: "";
	    width: 8px;
	    height: 12px;
	    float: left;
	    background-image: url(../../../img/assets/interface-sprite.svg);
	    background-size: 720px 720px;
	    background-repeat: no-repeat;
	    background-position: -446px -156px;
	    margin-right: 12px;
	    position: absolute;
	    left: 0;
	    top: 3px;
    }
    
    &:hover{
		color:#1145e9;
		
		&:before{
			background-position: -446px -192px;
		}
	}
	
	&--reverse{
		&:before{
			transform:scaleX(-1);
		}
	}
}