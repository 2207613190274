$alert-types: (
        "warning" : $warning,
        "danger" : $danger,
        "success" : $success
);

.alert {
  padding: $alert-padding-y $alert-padding-x;
  border-width: $alert-border-width;
  border-style: solid;
  font-weight: $alert-link-font-weight;
  margin-bottom: $alert-margin-bottom;

  @each $name, $bg-color in $alert-types {
    &.alert-#{$name} {
      color: color-contrast($bg-color);
      background-color: $bg-color;
      border-color: darken($bg-color, .1);
    }
  }

}