// Base color settings
@use "sass:math";

$lightgreen:#8FD26B;
$darkgreen:#00A49B;
$lightblue:#4285f4;
$darkblue: #1145e9;


// Custom VARB variables

$min_width: 320px;
$max_width: 1024px;

$border-color-checkbox:#e5e5e5;
$label-focus-color:#757575;
$border-color-checkbox-checked:#e5e5e5;
$border-color-radio:#e5e5e5;
$border-color-radio-checked:#e5e5e5;

$form-error-color:#FF4338;

$form-fields-border-radius: 4px;
$form-fields-border-color: #aaaaaa;
$form-fields-border-color-focus: #19197c;
$form-fields-color: #333333;
$form-fields-placeholder-color: $form-fields-border-color;

// Bootstrap default variables overwritten
$primary: $darkgreen;
//$secondary: $lightgreen;
$secondary: $lightgreen;
$blue: $lightblue;

//$light: #f8f8f8;
$danger: $form-error-color;
$success: $lightgreen;
$grid-gutter-width: 48px;
$spacer: math.div($grid-gutter-width, 4); // 12 px
$spacers: (
        0: 0,
        1: $spacer * .5, // 6px
        2: $spacer * 1, // 12px
        3: $spacer * 2, // 24px
        4: $spacer * 3, // 36px
        5: $spacer * 5 // 60px
);

$font-family-sans-serif: 'Roboto', sans-serif;
$body-color: #262626;
$link-hover-decoration: none;
$link-color: $primary;
$link-hover-color: $link-color;
$font-weight-bolder: 900;
$light: #f4f4f4;
$min-contrast-ratio: 1.8;
$btn-font-weight: 500;

//$container-max-widths: (); // unset all container sizes although i'd recommended not to. just use .container-fluid if you want the containers 100% width
$container-max-widths: (
        lg: 960px,
        xl: 1140px
);

$enable-gradients: false;

$border-color: #E5E5E5;
